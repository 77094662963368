import React from 'react';
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";


import {FaceLivenessDetector} from "@aws-amplify/ui-react-liveness";

const LiveFaceCheckDialog = ({ open,sessionId,onCancel, onComplete, onError,region }) => {

    return (
        <ClosableDialog open={open} fullScreen >
            <OnCloseDialogTitle >
                Identity Check
            </OnCloseDialogTitle>
           <DialogContent sx={{maxHeight:'100%'}}>
               <FaceLivenessDetector
                   sessionId={sessionId}
                   onUserCancel={onCancel}
                   region={region}
                   onAnalysisComplete={onComplete}
                   onError={(err) => {
                       console.error(err);
                       onError("Unable to complete liveness")
                   }}
               />
           </DialogContent>
        </ClosableDialog>
    );
};

LiveFaceCheckDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    sessionId:PropTypes.string.isRequired,
    region:PropTypes.string.isRequired,
    onCancel:PropTypes.func.isRequired,
    onComplete:PropTypes.func.isRequired,
    onError:PropTypes.func.isRequired
};

export default LiveFaceCheckDialog;
export { LiveFaceCheckDialog };

