import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";

import Typography from "@mui/material/Typography";

const LiveFaceStartDialog = ({ open, onStart }) => {

    return (
        <ClosableDialog open={open} fullscreen >
            <OnCloseDialogTitle>
                Verify its you!
            </OnCloseDialogTitle>
           <DialogContent>
               <Stack spacing={3}>
                   <Typography variant={"h3"}>We need to know its you</Typography>
                   <Typography>Before we get started, we need to verify your identity using your selfie camera, or a webcam attached to your computer.</Typography>
                   <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                       <Button variant={"contained"} color={"primary"} onClick={onStart}>Begin</Button>
                   </Stack>
               </Stack>

           </DialogContent>
        </ClosableDialog>
    );
};

LiveFaceStartDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onStart: PropTypes.func.isRequired,
};

export default LiveFaceStartDialog;
export { LiveFaceStartDialog };

