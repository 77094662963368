import {createTheme, ThemeProvider} from '@mui/material/styles';
import {IntlProvider} from "react-intl";
import 'dayjs/locale/en'; 

let theme = createTheme({
    typography: {
        color: "#646268",
        body1: {
            color: "#646268"
        },
        h1: {
            fontSize: '2rem',
            fontWeight: 500,
        },
    },
    palette: {
        appBar: {
            main: '#fff',
            contrastText: '#646268',
        },
        common: {
            black: '#646268',
            white: '#fffff'
        },
        primary: {
            main: '#5ab494',
            contrastText: '#ffffff'
        },
        customer: {
            main: '#4c2079',
            contrastText: '#ffffff'
        },
        inventory: {
            main: '#29b893',
            contrastText: '#ffffff'
        },
        quote: {
            main: '#3b6686',
            contrastText: '#ffffff'
        },
        prospectiveCustomer: {
            main: '#3b6686',
            contrastText: '#ffffff'
        },
        activeCustomer: {
            main: '#5ab494',
            contrastText: '#ffffff'
        },
        expiredCustomer: {
            main: '#3a3a3c',
            contrastText: '#ffffff'
        },
        pricing: {
            currency: '#646268',
        },
        background: {
            default: '#fffff',
            paper: '#fffff'
        },
        lightGray:{
            main:"#D3D3D3"
        },
        mediumGray:{
            main:"#A3A3A3"
        },
        darkGray:{
          main:"#6D6D6D"
        }
    },
});
theme = createTheme(theme, {
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                },
            },
        },
        MuiBackdrop:{
            styleOverrides: {
                root: {
                    backgroundColor: '#3B6C86CC'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                    backgroundColor: "#f2fefa",
                    borderColor: "#e3e9e7",
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                }
            }
        },
    }
});

const FlexiThemeProvider = ({children}) => {
    return <IntlProvider locale={"en-ae"}><ThemeProvider theme={theme}>{children}</ThemeProvider></IntlProvider> 
    /* return <IntlProvider locale="en-ae">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-ae">
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </LocalizationProvider>
    </IntlProvider> */
}
export default FlexiThemeProvider
export {FlexiThemeProvider}