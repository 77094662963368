import React from 'react';
import PropTypes from 'prop-types';
import AuthContext from "../../../contexts/authContext";
import DealerUserProfileContext from "../../../contexts/dealerUserProfileContext";
import LoadingDialog from "../../atoms/LoadingDialog";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client";

import LiveFaceCheckDialog from "../../molecules/LiveFaceCheckDialog";
import LiveFaceStartDialog from "../../molecules/LiveFaceStartDialog";
import LiveFaceCompleteDialog from "../../molecules/LiveFaceCompleteDialog";
import LiveFaceErrorDialog from "../../molecules/LiveFaceErrorDialog";

const LoginProviderFactory = React.lazy(() => import( "../../organisms/LoginProviderFactory"))
const FlexiTemplate = React.lazy(() => import( "../FlexiTemplate"))
const DealerUserProfileProvider = React.lazy(() => import( "../../organisms/DealerUserProfileProvider"))
const CustomerAppBar = React.lazy(() => import( '../../molecules/CustomerAppBar'))

const LiveFaceCheck = () => {
    const [mode, setMode] = React.useState("start")
    const [sessionId, setSessionId] = React.useState(null);
    const [submissionId, setSubmissionId] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const user = React.useContext(DealerUserProfileContext);

    const CreateLiveFaceSessionQuery = gql`
        mutation CREATE_LIVE_FACE_SESSION {
            livenessSessionCreate {
                result {
                    sessionId
                    id
                }
                ok
                errors {
                    messages
                    field
                }
            }
        }
    `

    const SubmitLiveFaceSessionQuery = gql`
        mutation SUBMIT_LIVE_FACE_SESSION($sessionId: ID!) {
            livenessSessionUpdate(sessionId: $sessionId) {
                result {
                    id
                    uuid
                    updatedAt
                    success
                    createdAt
                }
                ok
                errors {
                    messages
                    field
                }
            }
        }
    `


    const [createLiveFaceSession] = useMutation(CreateLiveFaceSessionQuery, {});
    const [submitLiveFaceSession] = useMutation(SubmitLiveFaceSessionQuery, {
        variables: {
            sessionId: submissionId
        }
    });

    const handleAnalysisComplete = () => {
        submitLiveFaceSession().then(({data}) => {
            if (data?.livenessSessionUpdate?.ok && data?.livenessSessionUpdate?.result?.success) {
                setMode("complete")
            } else {
                console.error({error: data})
                setError("Can't confirm face detection")
            }
        }).catch((err) => {
            setError("Can't confirm face detection")
            console.error({error: err})
        })
    }

    const createSession = () => {
        setLoading(true);
        setError(null);
        setSessionId(null);
        setSubmissionId(null);
        createLiveFaceSession().then(({data}) => {
            if (data?.livenessSessionCreate?.ok) {
                setSessionId(data?.livenessSessionCreate.result.sessionId)
                setSubmissionId(data?.livenessSessionCreate.result.id)
                setMode("check")
                setLoading(false);
            } else {
                setError("Unable to create session.")
                console.warn({error: data})
                setLoading(false);
            }
        }).catch(error => {
            setError("Unable to create session.")
            console.warn({error})
            setLoading(false);
        })

    }
    if (error){
        <LiveFaceErrorDialog open onClose={() => {setMode("start")}} onRetry={createSession}/>
    }
    if (loading) {
        return <LoadingDialog/>;
    }
    if (mode === 'start') {
        return <LiveFaceStartDialog open onStart={createSession}/>;
    }
    if (mode === 'check') {
        return <LiveFaceCheckDialog
            open
            sessionId={sessionId}
            onCancel={createSession}
            region={process.env.REACT_APP_AWS_REGION}
            onComplete={handleAnalysisComplete}
            onError={(err) => {
                console.error(err);
                setError("Unable to complete liveness")
            }}
        />
    }
    if (mode === 'complete') {
        return <LiveFaceCompleteDialog open onClose={() => {
            setLoading(true);
            user?.refresh && user.refresh();
        }}/>
    }
    return <LiveFaceErrorDialog open onClose={() => {setMode("start")}} onRetry={createSession}/>

}
const TemplateConfigurator = ({children}) => {
    const {logout: onLogout} = React.useContext(AuthContext);
    const user = React.useContext(DealerUserProfileContext);

    return (
        <FlexiTemplate onLogout={onLogout} user={user}>
            <CustomerAppBar
                user={user}
                onLogout={onLogout}
            />
            { user.hasLiveFace===false ?  (


                <LiveFaceCheck/>

            ):children}
        </FlexiTemplate>
    )
}
TemplateConfigurator.propTypes = {
    children: PropTypes.node,
}

const CustomerLoggedInFlexiTemplate = ({
                                           method, endpoint, children
                                       }) => {
    return (
        <React.Suspense fallback={<LoadingDialog/>}>
            <LoginProviderFactory method={method} endpoint={endpoint}>
                <DealerUserProfileProvider>
                    <TemplateConfigurator>{children}</TemplateConfigurator>
                </DealerUserProfileProvider>
            </LoginProviderFactory>
        </React.Suspense>
    )

}
CustomerLoggedInFlexiTemplate.propTypes = {
    chidren: PropTypes.node
}


export default CustomerLoggedInFlexiTemplate
export {CustomerLoggedInFlexiTemplate}