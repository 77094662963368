import React from 'react';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import PropTypes from 'prop-types';
import ClosableDialog from '../../atoms/ClosableDialog';
import { OnCloseDialogTitle } from "../../atoms/ClosableDialogTitle";
import DialogContent from "@mui/material/DialogContent";

import Typography from "@mui/material/Typography";

const LiveFaceCompleteDialog = ({ open, onClose, onRetry }) => {

    return (
        <ClosableDialog open={open} fullScreen >
            <OnCloseDialogTitle onClose={onClose} >
                Something went wrong!
            </OnCloseDialogTitle>
           <DialogContent>
               <Stack spacing={3}>
                   <Typography variant={"h3"}>We can't verify its you at this time</Typography>
                   <Typography>If problems persist, contact support</Typography>
                   <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                       <Button variant={"contained"} color={"primary"} onClick={onRetry}>Try Again</Button>
                   </Stack>
               </Stack>
           </DialogContent>
        </ClosableDialog>
    );
};

LiveFaceCompleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onRetry: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default LiveFaceCompleteDialog;
export { LiveFaceCompleteDialog };

