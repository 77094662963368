import React from 'react';
import PropTypes from 'prop-types';

const DealerUserProfileContext = React.createContext({
    loading: false,
    firstName: '',
    lastName: '',
    profileImage: '',
    id:'',
    refresh:()=>{},
    onChangeProfileData: () => {},
    onChangeProfileImage: () => {},
    onChangePassword: () => {},
});

export {DealerUserProfileContext};
export default DealerUserProfileContext

DealerUserProfileContext.propTypes = {
    loading: PropTypes.bool.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    profileImage: PropTypes.string,
    id:PropTypes.string.isRequired,
    onChangeProfileData: PropTypes.func,
    onChangeProfileImage: PropTypes.func,
    onChangePassword: PropTypes.func,
    refresh:PropTypes.func
}
